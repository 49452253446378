// @file School permissions students settings store
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { LibraryPermissions as LibraryPermissionsApi } from '@@/dashboard/padlet_api'
import {
  LibraryType,
  SchoolStudentPermissionBoolean,
  SnackbarNotificationType,
  WallPermission,
  WallPrivacyOption,
} from '@@/enums'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { Library, SchoolLibraryPermission, SchoolLibraryStudentPermission } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref, shallowReactive } from 'vue'

enum SchoolPermissionsStudentsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export const useSettingsSchoolPermissionsStudentsStore = defineStore('settingsSchoolPermissionsStudents', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

  // State
  const permissionsState = shallowReactive<SchoolLibraryStudentPermission>({
    canMakeWalls: false,
    canMakePrivateWalls: false,
    canMakeLibraryWideHiddenWalls: false,
    canMakeLibraryWideDashboardWalls: false,
    canMakePasswordWalls: false,
    canMakeSecretLoggedInVisitorsWalls: false,
    canMakeSecretAllVisitorsWalls: false,
    canMakePublicWalls: false,
    defaultWallPrivacy: WallPrivacyOption.LibraryWideUnlisted,
    defaultWallVisitorPermission: WallPermission.Write,
    canViewGallery: false,
    canAccessWallsFromOutsideSchool: false,
    canInviteCollaboratorsFromOutsideSchool: false,
    canAccessNonSchoolLibraries: false,
    canTransferWalls: false,
  })

  const schoolPermissionsStudentsStatus = ref(SchoolPermissionsStudentsStatus.Loading)
  const library = ref<Library>()
  const defaultPrivacy = ref<WallPrivacyOption>(WallPrivacyOption.Private)

  // Getters
  const isSchoolLibrary = computed(() => library.value?.libraryType === LibraryType.School)
  const isSchoolPermissionsStudentsLoading = computed(
    () => schoolPermissionsStudentsStatus.value === SchoolPermissionsStudentsStatus.Loading,
  )

  const disabledStudentDefaultPrivacy = computed(() => {
    if (defaultPrivacy.value === WallPrivacyOption.Private && !permissionsState.canMakePrivateWalls) return true

    if (
      defaultPrivacy.value === WallPrivacyOption.LibraryWideUnlisted &&
      !permissionsState.canMakeLibraryWideHiddenWalls
    )
      return true

    if (
      defaultPrivacy.value === WallPrivacyOption.LibraryWideListed &&
      !permissionsState.canMakeLibraryWideDashboardWalls
    )
      return true

    if (defaultPrivacy.value === WallPrivacyOption.PasswordProtected && !permissionsState.canMakePasswordWalls)
      return true

    if (
      defaultPrivacy.value === WallPrivacyOption.LoggedInUsersOnly &&
      !permissionsState.canMakeSecretLoggedInVisitorsWalls
    )
      return true

    if (defaultPrivacy.value === WallPrivacyOption.Secret && !permissionsState.canMakeSecretAllVisitorsWalls)
      return true

    if (defaultPrivacy.value === WallPrivacyOption.Public && !permissionsState.canMakePublicWalls) return true

    return false
  })

  // if the default privacy is disabled, attempt to set the default to an enabled privacy option in the following order
  // this is not a descending order of security but of what privacy options we think are most preferable by users
  const mostSecureEnabledStudentPrivacyOption = computed(() => {
    if (permissionsState.canMakeLibraryWideHiddenWalls) return WallPrivacyOption.LibraryWideUnlisted
    if (permissionsState.canMakeLibraryWideDashboardWalls) return WallPrivacyOption.LibraryWideListed
    if (permissionsState.canMakeSecretLoggedInVisitorsWalls) return WallPrivacyOption.LoggedInUsersOnly
    if (permissionsState.canMakeSecretAllVisitorsWalls) return WallPrivacyOption.Secret
    if (permissionsState.canMakePasswordWalls) return WallPrivacyOption.PasswordProtected
    if (permissionsState.canMakePrivateWalls) return WallPrivacyOption.Private
    if (permissionsState.canMakePublicWalls) return WallPrivacyOption.Public
    return WallPrivacyOption.Private
  })
  const isMakeAPadletForStudentsDisabled = computed(() => !permissionsState.canMakeWalls)
  const areAllStudentPrivacyOptionsDisabled = computed(
    () =>
      !permissionsState.canMakePrivateWalls &&
      !permissionsState.canMakeLibraryWideHiddenWalls &&
      !permissionsState.canMakeLibraryWideDashboardWalls &&
      !permissionsState.canMakePasswordWalls &&
      !permissionsState.canMakeSecretLoggedInVisitorsWalls &&
      !permissionsState.canMakeSecretAllVisitorsWalls &&
      !permissionsState.canMakePublicWalls,
  )

  // Actions
  function setPermissionsAndDefaultPrivacyState(permissions: SchoolLibraryStudentPermission): void {
    defaultPrivacy.value = (permissions?.defaultWallPrivacy as WallPrivacyOption) ?? WallPrivacyOption.Private
    for (const permission in permissionsState) {
      permissionsState[permission] = permissions[permission] ?? false
    }
  }
  async function initialize(libraryObject?: Library): Promise<void> {
    if (libraryObject === undefined) {
      void globalSnackbarStore.genericFetchError()
      return
    }
    library.value = libraryObject
    await fetchLibraryPermissionsStudents(libraryObject.id)
  }

  async function fetchLibraryPermissionsStudents(libraryId: number): Promise<void> {
    try {
      schoolPermissionsStudentsStatus.value = SchoolPermissionsStudentsStatus.Loading

      const response = await LibraryPermissionsApi.fetch(libraryId)
      const permissions = (response.data as JsonAPIResource<SchoolLibraryPermission>)?.attributes.settings?.student

      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
      schoolPermissionsStudentsStatus.value = SchoolPermissionsStudentsStatus.Completed
    } catch (e) {
      void globalSnackbarStore.genericFetchError()
      schoolPermissionsStudentsStatus.value = SchoolPermissionsStudentsStatus.Errored
      captureFetchException(e, { source: 'SettingsSchoolPermissionsStudentsFetchPermissions' })
    }
  }

  async function updatePermissions(
    studentPermission: SchoolStudentPermissionBoolean,
    permissionValue: boolean,
    initialDefaultPrivacy: WallPrivacyOption,
  ): Promise<void> {
    const libraryId = library.value?.id as number
    try {
      const response = await LibraryPermissionsApi.update(libraryId, {
        student: { [studentPermission]: permissionValue },
      })
      const permissions = (response.data as JsonAPIResource<SchoolLibraryPermission>)?.attributes.settings?.student
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
    } catch (e) {
      queue.clear()
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permissions.'),
        notificationType: SnackbarNotificationType.error,
      })
      permissionsState[studentPermission] = !permissionValue
      defaultPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsLibraryPermissionsStudentsUpdatePermissions' })
    }
  }

  async function queueUpdatePermissions(studentPermission: SchoolStudentPermissionBoolean): Promise<void> {
    permissionsState[studentPermission] = !permissionsState[studentPermission]
    const initialDefaultPrivacy = defaultPrivacy.value
    if (disabledStudentDefaultPrivacy.value) {
      defaultPrivacy.value = mostSecureEnabledStudentPrivacyOption.value
    }
    void queue.enqueue(
      'updatePermissions',
      async () =>
        await updatePermissions(studentPermission, permissionsState[studentPermission], initialDefaultPrivacy),
    )
  }

  function confirmDisableCanAccessNonSchoolLibraries(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title: __('Are you sure you want to remove access to personal workspaces?'),
      body: __('Your students will not be able to find or transfer padlets from their personal workspaces.', {
        libraryName: library.value?.name,
      }),
      forceFullWidthButtons: true,
      confirmButtonText: __('Disable'),
      cancelButtonText: __('Nevermind'),
      afterConfirmActions: [
        async () => await queueUpdatePermissions(SchoolStudentPermissionBoolean.CanAccessNonSchoolLibraries),
      ],
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
    })
  }

  async function updateDefaultPrivacy(
    wallPrivacy: WallPrivacyOption,
    initialDefaultPrivacy: WallPrivacyOption,
  ): Promise<void> {
    const libraryId = library.value?.id as number
    try {
      const response = await LibraryPermissionsApi.update(libraryId, {
        student: { defaultWallPrivacy: wallPrivacy },
      })
      const permissions = (response.data as JsonAPIResource<SchoolLibraryPermission>)?.attributes.settings?.student
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
    } catch (e) {
      queue.clear()
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating default privacy.'),
        notificationType: SnackbarNotificationType.error,
      })
      defaultPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsLibraryPermissionsStudentsUpdateDefaultWallPrivacy' })
    }
  }

  async function queueUpdateDefaultPrivacy(wallPrivacy: WallPrivacyOption): Promise<void> {
    const initialDefaultPrivacy = defaultPrivacy.value
    defaultPrivacy.value = wallPrivacy
    if (wallPrivacy === WallPrivacyOption.Private) {
      permissionsState.defaultWallVisitorPermission = WallPermission.Read
    }
    void queue.enqueue(
      'updateStudentDefaultWallPrivacy',
      async () => await updateDefaultPrivacy(wallPrivacy, initialDefaultPrivacy),
    )
  }

  async function updateStudentDefaultWallPermission(
    permission: WallPermission,
    initialPermission: WallPermission,
  ): Promise<void> {
    const libraryId = library.value?.id as number
    try {
      const response = await LibraryPermissionsApi.update(libraryId, {
        student: { defaultWallVisitorPermission: permission },
      })
      const permissions = (response.data as JsonAPIResource<SchoolLibraryPermission>)?.attributes.settings?.student
      if (permissions?.defaultWallVisitorPermission !== undefined) {
        permissionsState.defaultWallVisitorPermission = permissions.defaultWallVisitorPermission
      }
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permission.'),
        notificationType: SnackbarNotificationType.error,
      })
      queue.clear()
      permissionsState.defaultWallVisitorPermission = initialPermission
      captureFetchException(e, { source: 'SettingsLibraryPermissionsStudentsUpdateDefaultWallPermission' })
    }
  }

  async function queueUpdateWallDefaultPermission(wallPermission: WallPermission): Promise<void> {
    const initialDefaultWallPermission = permissionsState.defaultWallVisitorPermission as WallPermission
    permissionsState.defaultWallVisitorPermission = wallPermission
    void queue.enqueue(
      'updateStudentDefaultWallPermission',
      async () => await updateStudentDefaultWallPermission(wallPermission, initialDefaultWallPermission),
    )
  }

  return {
    // State
    permissionsState,
    library,
    defaultPrivacy,
    // Getters
    isSchoolLibrary,
    isSchoolPermissionsStudentsLoading,
    disabledStudentDefaultPrivacy,
    isMakeAPadletForStudentsDisabled,
    areAllStudentPrivacyOptionsDisabled,

    // Actions
    initialize,
    queueUpdatePermissions,
    queueUpdateDefaultPrivacy,
    queueUpdateWallDefaultPermission,
    confirmDisableCanAccessNonSchoolLibraries,
  }
})
